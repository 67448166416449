var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-backtop",
    _vm._g(
      _vm._b(
        { staticClass: "z-backtop", attrs: { bottom: _vm.bottom } },
        "el-backtop",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }