import Vue from 'vue'
import VueI18n from 'vue-i18n'

// import element ui locale
import EnElementLocale from 'element-ui/lib/locale/lang/en'
import CNElementLocale from 'element-ui/lib/locale/lang/zh-CN'

//import defined locale
import enLocale from '@/locales/lang/en'
import zhLocale from '@/locales/lang/zh-cn'
import ElementLocale from 'element-ui/lib/locale'

Vue.use(VueI18n)

const messages = {
	en: {
		...EnElementLocale,
		...enLocale
	},
	zh: {
		...CNElementLocale,
		...zhLocale
	}
}

export const getLocale = () => {
	const cookieLanguage = ''
	if (cookieLanguage) {
		document.documentElement.lang = cookieLanguage
		return cookieLanguage
	}
	const language = navigator.language.toLowerCase()
	const locales = Object.keys(messages)
	for (const locale of locales) {
		if (language.indexOf(locale) > -1) {
			document.documentElement.lang = locale
			return locale
		}
	}

	return 'en'
}
// init i18n
const i18n = new VueI18n({
	locale: getLocale(),
	messages: messages
})

ElementLocale.i18n((key: string, value: string) => i18n.t(key, value))

export default i18n
