import Storage from './storage'
import Cookies from 'js-cookie'
import service from './request'
import router from '@/router'
import { MessageBox } from 'element-ui'

const idTokenKey = 'LOGIN_ID_TOKEN'
export const getIdToken = () => Storage.get(idTokenKey) || ''
export const setIdToken = (token: string) => Storage.set(idTokenKey, token)
// User
const tokenKey = 'LOGIN_ACCESS_TOKEN'
export const getToken = () => Storage.get(tokenKey)
export const setToken = (token: string) => {
	Storage.set(tokenKey, token)
	const domain =
		location.hostname.indexOf('localhost') > -1
			? location.hostname
			: '.gblinker.com'
	Cookies.set('Authorization', `Bearer ${token}`, {
		domain: domain,
		expires: 30
	})
}
export const removeToken = () => {
	window.localStorage.removeItem(tokenKey)
	const domain =
		location.hostname.indexOf('localhost') > -1
			? location.hostname
			: '.gblinker.com'
	Cookies.remove('Authorization', {
		domain: domain
	})
}

const languageKey = 'language'
export const getLanguage = () => Storage.get(languageKey)
export const setLanguage = (language: string) =>
	Storage.set(languageKey, language)

/**
 * 登录
 * @param code
 * @returns
 */
export const loginWithIDaaSCode = async (code: string): Promise<any> => {
	const { data } = await service.post('/v1/jobline/auth2/login', {
		code: code
	})
	if (data) {
		const { token, id_token = '' } = data
		setToken(token)
		setIdToken(id_token)
		return true
	}
	return false
}
export const goToLogin = () => {
	removeToken()
	router.push({
		path: '/login'
	})
}
const userInfoKey = 'USER_INFO'
export const getUserInfo = async (): Promise<any> => {
	const { data } = await service.get('/v1/jobline/auth2/user')
	if (data) Storage.set(userInfoKey, JSON.stringify(data))
	return data
}
const loginConfigKey = 'LOGIN_CONFIG'
export const getLoginConfig = () => {
	return JSON.parse(Storage.get(loginConfigKey))
}

export const logout = async (): Promise<any> => {
	const confirm = await MessageBox.confirm('确定要退出登录吗？', '提示', {
		type: 'warning'
	}).catch(() => false)
	if (!confirm) return
	removeToken()
	Storage.remove(userInfoKey)
	const config = getLoginConfig()
	let url = `${location.origin}/login`
	if (config.logout_url) {
		const token = getIdToken()
		url = `${config.logout_url}?id_token_hint=${token}&post_logout_redirect_uri=${location.origin}/login`
	}
	window.location.href = url
	return true
}
export const loginInit = async () => {
	const { data } = await service.get('/v1/jobline/auth2/login-init')
	if (data) Storage.set(loginConfigKey, JSON.stringify(data))
	return data
}

export const goToAccountCenter = async (): Promise<any> => {
	const config = getLoginConfig() || loginInit()
	window.open(config.user_center_url, '_blank')
}

export const goProfile = () => {
	router.push({
		path: '/profile'
	})
}
