import 'normalize.css'
import './assets/styles/index.scss'
import Vue, { DirectiveOptions } from 'vue'
import App from './app.vue'
import router from './router'
import store from './store'
import i18n from './locales'
import request from './utils/request'
import * as directives from '@/directives'
import * as filters from '@/filters'
import VConsole from 'vconsole'

Vue.prototype.$axios = request
Vue.prototype.$wx = window.wx
// Register global directives
Object.keys(directives).forEach(key => {
	Vue.directive(key, (directives as { [key: string]: DirectiveOptions })[key])
})

// Register global filter functions
Object.keys(filters).forEach(key => {
	Vue.filter(key, (filters as { [key: string]: Function })[key])
})
if (
	['localhost', 'dev.op.gblinker.com', '192.168.0.7'].includes(
		location.hostname
	)
) {
	console.log(location.hostname)
	new VConsole()
}

// Base Component
import BaseComponent from './components/base'
Vue.use(BaseComponent)
new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app')
