import { getToken, goToLogin } from '@/utils/auth'
import axios, { AxiosResponse } from 'axios'
import { Message } from 'element-ui'
import router from '@/router'
const baseUrl = process.env.VUE_APP_API_BASE_URL
let unAuthorization = false

const service = axios.create({
	baseURL: baseUrl,
	timeout: 30000
})

service.interceptors.request.use(
	config => {
		config.params = Object.assign(config.params || {}, {
			_: +new Date() //disabled http cache
		})
		unAuthorization = false
		const bearerToken = getToken()
		if (bearerToken) {
			config.headers['Authorization'] = `Bearer ${bearerToken || ''}`
			config.headers['api_key'] = 'a7269c2d9729242514d1060cc0a21743'
		}
		return config
	},
	error => {
		return Promise.reject(error)
	}
)
service.interceptors.response.use(
	(res: AxiosResponse) => {
		const status = res.status
		if (status >= 200 && status < 300) {
			return Promise.resolve(res)
		} else {
			return Promise.reject(res)
		}
	},
	error => {
		let message = error.message || '操作失败，请重试'
		const { status, data } = error.response || {}
		if (status === 401) {
			// 用户信息过期, 删除用户token
			goToLogin()
			return
		}
		const noPermission = status === 403
		if (noPermission) {
			if (window.location.href !== `${window.location.origin}/login`) {
				router
					.push({
						path: '/login'
					})
					.catch((error: Error) => error)
			}
		}
		if (data && data.message) message = data.message
		if (Array.isArray(message)) message = message[0]
		message = message.replace('_', ' ')
		if (unAuthorization && noPermission) return
		Message({
			offset: 64,
			message,
			showClose: true,
			type: 'error'
		})
		if (noPermission) unAuthorization = true
		// 接口错误message统一提示
		return Promise.reject(error)
	}
)

export default service
