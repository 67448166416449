import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/styles/element-variables.scss'
import { VueConstructor } from 'vue'
import Locale from '@/locales'

export default {
	install: (Vue: VueConstructor) => {
		Vue.use(ElementUI, {
			Locale
		})
		const components = require.context('./', false, /\.vue$/)
		components.keys().map(filename => {
			const name = filename.replace(/^\.\//, '').replace(/\.vue$/, '')
			const config = components(filename)
			Vue.component(name, config.default || config)
		})
	}
}
