var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { ref: "wrapper", staticClass: "app-wrapper" }, [
    _c("div", { staticClass: "fixed-header" }, [_c("b-header")], 1),
    _c(
      "div",
      { staticClass: "main-container" },
      [
        _c("app-main"),
        _c("div", { staticClass: "fixed-footer" }, [_c("b-footer")], 1),
        _c(
          "z-backtop",
          {
            attrs: {
              starget: "#app",
              right: _vm.backtopPos.right,
              bottom: _vm.backtopPos.bottom,
            },
          },
          [
            _c("div", { staticClass: "backtop-up" }, [
              _c("i", { staticClass: "el-icon-arrow-up" }),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }