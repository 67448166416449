import { DirectiveOptions } from 'vue'

export const debounce: DirectiveOptions = {
	inserted(el, { value, arg }) {
		const delay = Number(arg) || 1000
		let timer: null | ReturnType<typeof setTimeout> = null
		el.addEventListener('click', () => {
			if (timer) {
				clearTimeout(timer)
			}
			timer = setTimeout(() => {
				value()
			}, delay)
		})
	}
}
