var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("header", { staticClass: "header" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "menu" },
      [
        _c(
          "router-link",
          {
            staticClass: "menu-item",
            class: { active: _vm.activeName == "dashboard" },
            attrs: { to: "/dashboard" },
          },
          [
            _c(
              "svg",
              { staticClass: "icon", attrs: { "aria-hidden": "true" } },
              [_c("use", { attrs: { "xlink:href": "#icon-icon_search" } })]
            ),
            _vm._v("操作"),
          ]
        ),
        _c(
          "router-link",
          {
            staticClass: "menu-item",
            class: { active: _vm.activeName == "order" },
            attrs: { to: "/user/order" },
          },
          [
            _c(
              "svg",
              { staticClass: "icon", attrs: { "aria-hidden": "true" } },
              [
                _c("use", {
                  attrs: { "xlink:href": "#icon-icon_record-order" },
                }),
              ]
            ),
            _vm._v("下单"),
          ]
        ),
        _c(
          "router-link",
          {
            staticClass: "menu-item",
            class: { active: _vm.activeName == "packages" },
            attrs: { to: "/user/packages" },
          },
          [
            _c(
              "svg",
              { staticClass: "icon", attrs: { "aria-hidden": "true" } },
              [_c("use", { attrs: { "xlink:href": "#icon-icon_search" } })]
            ),
            _vm._v("客户包裹"),
          ]
        ),
        _c(
          "router-link",
          {
            staticClass: "menu-item",
            class: { active: _vm.activeName == "warehouse" },
            attrs: { to: "/warehouse" },
          },
          [
            _c(
              "svg",
              { staticClass: "icon", attrs: { "aria-hidden": "true" } },
              [_c("use", { attrs: { "xlink:href": "#icon-icon_work_order" } })]
            ),
            _vm._v("仓储"),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "profile" },
      [
        _c(
          "el-dropdown",
          { on: { command: _vm.handleCommand } },
          [
            _c("el-avatar", [_vm._v(_vm._s(_vm.userName.substr(0, 1)))]),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              [
                _vm.isLogin && _vm.userName
                  ? _c("el-dropdown-item", [
                      _vm._v("账号：" + _vm._s(_vm.userName)),
                    ])
                  : _vm._e(),
                _vm.isLogin && _vm.userName
                  ? _c("el-dropdown-item", { attrs: { command: "profile" } }, [
                      _vm._v("我的二维码"),
                    ])
                  : _vm._e(),
                _c("el-dropdown-item", { attrs: { command: "account" } }, [
                  _vm._v("用户中心"),
                ]),
                _vm.isLogin
                  ? _c("el-dropdown-item", { attrs: { command: "logout" } }, [
                      _vm._v("退出登录"),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "logo" }, [
      _c("img", {
        attrs: {
          src: "//res-cn.public.gblinker.com/gblinker/ops_assets/logo.png",
        },
      }),
      _c("label", { staticClass: "name" }, [_vm._v("格布小包")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }