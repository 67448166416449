
import {
	getToken,
	getUserInfo,
	goProfile,
	goToAccountCenter,
	loginInit,
	logout
} from '@/utils/auth'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { RouteRecord } from 'vue-router'
import Storage from '@/utils/storage'

@Component({
	name: 'BHeader',
	components: {},
	data() {
		return {}
	}
})
export default class extends Vue {
	private userName = ''
	private activeName = ''
	private isLogin = false
	@Watch('$route')
	routeChange(to: RouteRecord) {
		this.isLogin = !!getToken()
		this.activeName = to.name || ''
		this.getUserInfo()
	}
	created() {
		loginInit()
		this.activeName = this.$route.name || ''
		this.isLogin = !!getToken()
		this.getUserInfo()
	}
	getUserInfo() {
		const user = JSON.parse(Storage.get('USER_INFO')) || {}
		const { name = '' } = user as any
		this.userName = name
	}
	handleCommand(command: string) {
		if (command === 'profile') {
			return goProfile()
		}
		if (command === 'logout') {
			return logout()
		}
		if (command === 'account') {
			return goToAccountCenter()
		}
	}
}
