
import { Component, Vue } from 'vue-property-decorator'
import AppMain from '@/layout/app-main.vue'
import { BHeader, BFooter } from '@/components/biz'
import { debounce } from 'lodash'
@Component({
	name: 'Layout',
	components: { AppMain, BHeader, BFooter }
})
export default class Layout extends Vue {
	private backtopPos = {
		right: 35,
		bottom: 100
	}
	created() {
		const debouncedResize = debounce(() => {
			const clientWidth = document.body.clientWidth
			if (clientWidth < 560) {
				this.backtopPos = {
					right: 20,
					bottom: 80
				}
			} else {
				this.backtopPos = {
					right: 35,
					bottom: 100
				}
			}
		}, 300)
		window.addEventListener('resize', debouncedResize)
	}
}
