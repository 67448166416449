
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
	name: 'ZButton'
})
export default class extends Vue {
	@Prop({ default: '' }) type!: string
	@Prop({ default: 'medium' }) size!: string
}
