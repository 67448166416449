
import { Component, Vue } from 'vue-property-decorator'
@Component({
	name: 'AppMain'
})
export default class extends Vue {
	get key() {
		return this.$route.path
	}
	get keepAliceCache() {
		return !this.$route.meta?.noCache
	}
}
