<template>
	<el-pagination
		class="z-pagination"
		v-bind="$attrs"
		background
		v-on="$listeners"
	>
		<slot />
	</el-pagination>
</template>
<script>
export default {
	name: 'z-pagination'
}
</script>

<style lang="scss" scope>
.z-pagination {
	margin: 10px 0;
}
</style>
