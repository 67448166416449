var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "app-main" },
    [
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [
          _vm.keepAliceCache
            ? _c("keep-alive", [_c("router-view", { key: _vm.key })], 1)
            : _vm._e(),
          !_vm.keepAliceCache ? _c("router-view", { key: _vm.key }) : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }