import Vue from 'vue'
import VueRouter, { RouteConfig, Route } from 'vue-router'
import Layout from '@/layout/index.vue'
import { getToken, removeToken } from '@/utils/auth'
import { Message } from 'element-ui'

Vue.use(VueRouter)
const routes: Array<RouteConfig> = [
	{
		path: '/',
		component: Layout,
		children: [
			{
				path: '/home',
				component: () => import('@/views/home.vue'),
				name: 'home',
				meta: {
					title: 'Home'
				}
			},
			{
				path: '/profile',
				component: () => import('@/views/profile.vue'),
				name: 'profile',
				meta: {
					title: 'Profile'
				}
			},
			{
				path: '/login',
				component: () => import('@/views/login.vue'),
				name: 'home',
				meta: {
					title: 'Login'
				}
			},
			{
				path: '/dashboard',
				component: () => import('@/views/ops/index.vue'),
				name: 'dashboard',
				meta: {
					title: 'OPS'
				}
			},
			{
				path: '/ops/barcode',
				component: () => import('@/views/ops/input-barcode.vue'),
				name: 'InputBarcode',
				meta: {
					title: '录入商品条码'
				}
			},
			{
				path: '/ops/ordering',
				component: () => import('@/views/ops/ordering.vue'),
				name: 'dashboard',
				meta: {
					title: '排货'
				}
			},
			{
				path: '/ops/ordering/get-tracking-number',
				component: () => import('@/views/ops/get-tracking-number.vue'),
				name: 'dashboard',
				meta: {
					title: '获取远程单号'
				}
			},
			{
				path: '/ops/ordering/address-validate',
				component: () => import('@/views/ops/address-validate.vue'),
				name: 'AddressValidate',
				meta: {
					title: '地址验证'
				}
			},
			{
				path: '/ops/edit',
				component: () => import('@/views/ops/edit-package.vue'),
				name: 'dashboard',
				meta: {
					title: '排货'
				}
			},
			{
				path: '/dashboard/labels',
				component: () => import('@/views/ops/print-labels.vue'),
				name: 'label',
				meta: {
					title: '面单打印'
				}
			},
			{
				path: '/user/order',
				component: () => import('@/views/user/ordering.vue'),
				name: 'order',
				meta: {
					title: 'Order'
				}
			},
			{
				path: '/user/packages',
				component: () => import('@/views/user/packages.vue'),
				name: 'packages',
				meta: {
					title: 'Packages'
				}
			},
			{
				path: '/ops/get-exchange-tracking-number',
				component: () => import('@/views/ops/get-exchange-tracking-number.vue'),
				name: 'dashboard',
				meta: {
					title: '获取换单号'
				}
			}
		]
	},

	{
		path: '/warehouse',
		name: 'warehouse',
		component: Layout,
		redirect: '/warehouse/',
		meta: {
			title: 'Warehouse'
		},
		children: [
			{
				path: '',
				meta: {
					title: '仓库首页'
				},
				name: 'warehouse',
				component: () => import('@/views/warehouse/index.vue')
			},
			{
				path: 'shelf/in-shelf',
				meta: {
					title: '上架'
				},
				name: 'warehouse-in-shelf',
				component: () => import('@/views/warehouse/shelf/in-shelf.vue')
			},
			{
				path: 'shelf/out-shelf',
				component: () => import('@/views/warehouse/shelf/out-shelf.vue'),
				name: 'warehouse-out-shelf',
				meta: {
					title: '下架'
				}
			},
			{
				path: 'shelf/search',
				meta: {
					title: '货架查询'
				},
				name: 'warehouse-search',
				component: () => import('@/views/warehouse/shelf/search.vue')
			},
			{
				path: 'shelf/find-package',
				meta: {
					title: '找包裹'
				},
				name: 'warehouse-find-package',
				component: () => import('@/views/warehouse/shelf/find-package.vue')
			},
			{
				path: 'inbound/input',
				component: () => import('@/views/warehouse/inbound/input.vue'),
				name: 'inbound',
				meta: {
					title: '入库扫描'
				}
			},
			{
				path: 'inbound/search',
				component: () => import('@/views/warehouse/inbound/search.vue'),
				name: 'inbound',
				meta: {
					title: '入库查询'
				}
			},
			{
				path: 'inbound/exception',
				component: () => import('@/views/warehouse/inbound/exception.vue'),
				name: 'inbound-exception',
				meta: {
					title: '异常件'
				}
			},
			{
				path: 'package-merge',
				component: () => import('@/views/warehouse/inbound/package-merge.vue'),
				name: 'package-merge',
				meta: {
					title: '合包申请'
				}
			},
			{
				path: 'inbound/exception-input',
				component: () =>
					import('@/views/warehouse/inbound/exception-input.vue'),
				name: 'inbound-exception-input',
				meta: {
					title: '异常件录入'
				}
			},
			{
				path: 'inbound/exception-in-shelf',
				component: () =>
					import('@/views/warehouse/inbound/exception-in-shelf.vue'),
				name: 'inbound-exception-in-shelf',
				meta: {
					title: '异常件上架'
				}
			},
			{
				path: 'stock-out/box',
				meta: {
					title: '箱子列表'
				},
				name: 'stockout-box',
				component: () => import('@/views/warehouse/stockout/box.vue')
			},
			{
				path: 'stock-out/box/pack',
				meta: {
					title: '包裹装箱'
				},
				name: 'stockout-pack',
				component: () => import('@/views/warehouse/stockout/pack.vue')
			},
			{
				path: 'stock-out/scan',
				meta: {
					title: '扫描出库'
				},
				name: 'stockout-scan',

				component: () => import('@/views/warehouse/stockout/scan.vue')
			},
			{
				path: 'stock-out/history',
				meta: {
					title: '出库记录'
				},
				name: 'stockout-history',
				component: () => import('@/views/warehouse/stockout/history.vue')
			},
			{
				path: 'stock-out/tracking',
				meta: {
					title: '单号回填'
				},
				name: 'stockout-tracking',
				component: () => import('@/views/warehouse/stockout/tracking.vue')
			},
			{
				path: 'stock-out/packages',
				meta: {
					title: '打印待出库包裹'
				},
				name: 'stockout-tracking',
				component: () => import('@/views/warehouse/stockout/packages.vue')
			},
			{
				path: 'tools/order-print-time',
				meta: {
					title: '出库打单时间'
				},
				name: 'order-print-time',
				component: () => import('@/views/warehouse/tools/order-print-time.vue')
			},
			{
				path: 'tools/replace-label',
				meta: {
					title: '换单'
				},
				name: 'replace-label',
				component: () => import('@/views/warehouse/tools/replace-label.vue')
			},
			{
				path: 'tools/address-parse',
				meta: {
					title: '地址解析'
				},
				name: 'tools-address-parse',
				component: () => import('@/views/warehouse/tools/address-parse.vue')
			},
			{
				path: 'return/intercept',
				meta: {
					title: '接单'
				},
				name: 'return-intercept',
				component: () => import('@/views/warehouse/return/intercept.vue')
			},
			{
				path: 'return/return-package',
				meta: {
					title: '退件'
				},
				name: 'return-package',
				component: () => import('@/views/warehouse/return/return-package.vue')
			}
		]
	},
	{
		path: '/agent',
		name: 'agent',
		component: Layout,
		redirect: '/agent',
		meta: {
			title: 'Agent'
		},
		children: [
			{
				path: '',
				meta: {
					title: '客服短信发送'
				},
				name: 'sms',
				component: () => import('@/views/agent/sms.vue')
			}
		]
	},
	{
		path: '/404',
		component: () => import('@/views/error.vue'),
		meta: { hidden: true }
	},
	{
		path: '*',
		redirect: '/404',
		component: () => import('@/views/error.vue'),
		meta: { hidden: true }
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})
router.beforeEach(async (to: Route, from: Route, next: any) => {
	const { path, query } = to
	let token = getToken()
	if (token == 'gblinker') {
		token = ''
		removeToken()
	}
	// 如果用户没有token则跳转到登录页面
	if (token && path.indexOf('404') === -1) {
		return next()
	} else if (path == '/404' || path == '/login') {
		next()
	} else {
		Message.warning('请先登录')
		next({
			path: '/login'
		})
	}
})
export default router
