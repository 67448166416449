const StorageMethods = {
	store: window.localStorage,
	set: function(key: string, value: any) {
		this.store.setItem(key, JSON.stringify(value))
		return true
	},
	get: function(key: string) {
		const ttl = this.ttl(key)
		//已过期
		if (ttl == -1) {
			this.remove(key)
			return null
		}
		const value = this.store.getItem(key) || ''
		try {
			return JSON.parse(value)
		} catch (error) {
			return null
		}
	},
	// eslint-disable-next-line
	setex:function (key: string, value: any, ttl: number) {
		this.set(key, value)
		this.expire(key, ttl)
	},
	ttl: function(key: string) {
		const now = Math.round(+new Date() / 1000)
		const ttlKey = `expire:${key}`
		const expireTime = Number(this.store.getItem(ttlKey))
		if (!expireTime) return -2
		const ttl = expireTime - now
		return ttl > 0 ? ttl : -1
	},
	expire: function(key: string, ttl: number) {
		if (ttl && ttl < 1) return -2
		const now = Math.round(+new Date() / 1000)
		const expire = now + ttl
		const ttlKey = `expire:${key}`
		this.set(ttlKey, expire)
		return true
	},
	getAll: function() {
		const keys = this.store
		const obj: { [key in string]: any } = {}
		for (const i in keys) {
			obj[i] = keys[i] ? JSON.parse(keys[i]) : null
		}
		return obj
	},
	remove: function(key: string) {
		if (key) {
			const ttlKey = `expire:${key}`
			this.store.removeItem(key)
			this.store.removeItem(ttlKey)
		}
		return true
	},
	removeAll: function() {
		const keys = this.store
		for (const i in keys) {
			this.remove(i)
		}
		return true
	}
}

const Storage = {
	...StorageMethods
}

export default Storage

export const sessionStorageInstance = {
	...StorageMethods,
	store: window.sessionStorage
}
