var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "page", staticClass: "z-page" },
    [
      _c("header", { ref: "header", staticClass: "header" }, [
        _c(
          "div",
          { staticClass: "header-content" },
          [
            _vm._t("filter-menu"),
            _c(
              "div",
              { staticClass: "header-content__wrapper" },
              [_vm._t("header-content")],
              2
            ),
          ],
          2
        ),
      ]),
      _c(
        "main",
        { ref: "main", staticClass: "content", style: _vm.styles },
        [_vm.$slots.default ? _vm._t("default") : _vm._e()],
        2
      ),
      _vm.$slots.footer
        ? _c(
            "footer",
            { ref: "footer", class: ["footer", { fixed: "fixedFooter" }] },
            [_vm._t("footer")],
            2
          )
        : _vm._e(),
      [_vm._t("layout-aside-header")],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }