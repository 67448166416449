
import { isMobile } from '@/utils/utils'
import { isNaN } from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'
const defaultHeight = 900
@Component({})
export default class ZPage extends Vue {
	@Prop({ default: null }) private title?: string
	@Prop({ default: null }) private subtitle?: string
	@Prop({ default: true }) private fixedFooter?: boolean
	private mainHeight = defaultHeight
	mounted() {
		this.$nextTick(() => {
			this.getContentHeight()
		})
	}
	getContentHeight() {
		const page = this.$refs.page as HTMLElement
		const parentNode =
			(page.parentNode as HTMLElement) ||
			document.getElementsByClassName('main-warp')
		const containerHeight = parentNode.clientHeight || 1300
		const headerHeight = this.$refs.header
			? (this.$refs.header as HTMLElement).clientHeight
			: 0
		const footerHeight = this.$refs.footer
			? (this.$refs.footer as HTMLElement).clientHeight
			: 0
		const paddingTop = this.getStyle(parentNode, 'padding-top') || 20
		const paddingBottom = this.getStyle(parentNode, 'padding-bottom') || 20
		const height =
			containerHeight - headerHeight - footerHeight - paddingTop - paddingBottom
		const tableHeight = this.$route.meta?.tableHeight
		const defaultTableHeight = tableHeight || defaultHeight
		if (!isNaN(height) && height >= defaultTableHeight) {
			this.mainHeight = height
		} else {
			this.mainHeight = defaultTableHeight
		}
	}

	getStyle(obj: any, attr: string) {
		// 兼容IE浏览器
		const result = obj.currentStyle
			? obj.currentStyle[attr].replace('px', '')
			: (window.getComputedStyle(obj, null) as any)[attr].replace('px', '')
		return Number(result)
	}
	get styles() {
		if (this.$route.meta?.setMainHeight === false || isMobile()) return {}
		return { height: `${this.mainHeight}px` }
	}
}
