import printJS from 'print-js'
import * as _ from 'lodash'
import axios, { AxiosResponse } from 'axios'
import request from './request'

export const echo = function(msg: any) {
	console.log(msg)
}

export const isWechat = () => {
	//window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
	const ua = window.navigator.userAgent.toLowerCase()
	//通过正则表达式匹配ua中是否含有MicroMessenger字符串
	if (/MicroMessenger/i.test(ua)) {
		return true
	} else {
		return false
	}
}
export const isMobile = (): boolean => {
	return /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/.test(
		navigator.userAgent
	)
}
/** 字符计算 */
export const getStrLength = (str: string) => {
	let realLength = 0
	const len = str.length
	let charCode = -1
	for (let i = 0; i < len; i++) {
		charCode = str.charCodeAt(i)
		if (charCode >= 0 && charCode <= 128) realLength += 1
		else realLength += 2
	}
	realLength = realLength % 2 != 0 ? (realLength + 1) / 2 : realLength / 2
	return realLength
}
export const formatUserRole = (role: number): any => {
	if (role === 1) {
		return {
			label: '电商商家',
			type: 'success'
		}
	}
	if (role === 0) {
		return {
			label: '个人客户',
			type: 'info'
		}
	}
	if (role === 2) {
		return {
			label: '格布员工',
			type: 'danger'
		}
	}
	return {
		label: '未知',
		type: 'warning'
	}
}

const printerHost = 'http://127.0.0.1:10240'
// 打印机是否链接
export const isPrinterConnected = async (): Promise<boolean> => {
	const service = axios.create({
		baseURL: printerHost,
		timeout: 800
	})
	const res = await service.get('/ping').catch(() => {
		return null
	})
	console.log(res)
	return res ? true : false
}

export const printWithLocalPrinter = async (data: any) => {
	const service = axios.create({
		baseURL: printerHost,
		timeout: 1000
	})
	const res = await service.post('/print-labels', data).catch(() => {
		return null
	})
	console.log(res)
}

export const printLabel = async (package_num: string, title: string) => {
	const isConnected = await isPrinterConnected()
	const url = `${process.env.VUE_APP_API_BASE_URL}v1/jobline/generate/package-bar-code/${package_num}?title=${title}`
	if (!isConnected) {
		return printJS(url)
	}
	const list: any[] = []
	list.push({
		package_num,
		title,
		url
	})
	await printWithLocalPrinter(list)
}
//请求等待
export const sleep = (s: number) =>
	new Promise(resolve => setTimeout(resolve, s * 1000))

/**
 * 获取用户角色
 * @returns
 */
export const getUserRole = async () => {
	const { data } = await request
		.get('/v1/jobline/tools/user-role')
		.catch(() => {
			return {
				data: null
			}
		})
	if (data) {
		const roles: any = {}
		for (const item of data) {
			roles[item.role] = item
		}
		return roles
	}
	return {}
}
