import { get } from 'lodash'

// 空白占位符
export const placeholder = (data: string, placeholder = '-') => {
	return data ? data : placeholder
}

// 不足2位小数补充0
export const returnFloat = (value: string | number) => {
	// 处理undefined和null的情况
	if (value === null || value === undefined) {
		return placeholder(value)
	}
	let val = (Math.round(parseFloat(value.toString()) * 100) / 100).toString()
	const xsd = val.toString().split('.')
	if (xsd.length == 1) {
		val = val.toString() + '.00'
		return val
	}
	if (xsd.length > 1) {
		if (xsd[1].length < 2) {
			val = val.toString() + '0'
		}
		return val
	}
}

export const getValue = (
	obj: object,
	path: any[] | string,
	defaultValue: string | undefined = undefined
) => {
	return get(obj, path, defaultValue)
}
